import React from 'react';
import downloadFile from '../../../content/contact/wofmich_impressum.pdf';
import fb from '../../../content/images/fb.png';
import ig from '../../../content/images/ig.png';
import calling from '../../../content/images/Wolfmich_Calling2.gif';
import { CenterImage, Container } from './contactlogo.css.js';

const ContactLogo = () => (
  <Container>
    <CenterImage>
      <figure>
        <img style={{ maxWidth: '100%' }} src={calling} alt="Contact"></img>
      </figure>
    </CenterImage>
    <div
      style={{
        borderWidth: '1px',
        borderColor: '#fff',
        textAlign: 'justify',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        fontFamily: 'Muli',
      }}
    >
      <div style={{ marginBottom: '3em' }}>
        Monday: 11:00 - 19:00 <br />
        <br /> Tuesday: 11:00 - 19:00 <br />
        <br />
        Wednesday: 11:00 - 19:00 <br />
        <br />
        Thursday: 11:00 - 19:00 <br />
        <br />
        Friday: 11:00 - 19:00 <br />
        <br />
        Saturday: 11:00 - 18:00 <br />
        <br />
        Sunday: Closed
      </div>
      <div style={{ marginBottom: '1em' }}>
        {' '}
        <a href="tel:+4315813536">+43 1 5813536</a>
      </div>
      <div style={{ marginBottom: '2em' }}>
        <a href="mailto:hello@wolfmich.com">hello@wolfmich.com</a>
      </div>
      <div style={{ marginBottom: '2em' }}>
        <a
          href="https://www.google.com/maps/place/wolfmich/@48.1979011,16.353456,17z/data=!3m1!4b1!4m5!3m4!1s0x476d072c4e6fc3cf:0x5a0773f0f776da13!8m2!3d48.1978975!4d16.3556447"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gumpendorfer Straße 51
          <br />
          1060 Vienna
          <br />
          Austria
        </a>{' '}
      </div>
      <div>
        <a
          href="https://www.instagram.com/wolfmich_official/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={ig}
            style={{
              width: '5%',
            }}
            alt="Instagram"
          />
        </a>
      </div>
      <br />
      <div>
        <a
          href="https://www.facebook.com/1060vienna/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={fb}
            style={{
              width: '5%',
            }}
            alt="Facebook"
          />
        </a>
      </div>
      <br />
      <div>
        <a
          href={downloadFile}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            fontSize: '75%',
          }}
        >
          Impressum
        </a>
      </div>
    </div>
  </Container>
);

export default ContactLogo;
