import React from 'react';
import { Link } from 'gatsby';
import { Container, DropDown } from './nav.css';

const Nav = () => {
  return (
    <Container>
      <ul>
        <li>
          <a href="https://shop.wolfmich.com/">Shop</a>
        </li>
        <li>
          <Link to="/images">Gallery</Link>
        </li>

        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        <li>
          <Link to="/wolfmix">Wolfmix</Link>
        </li>
      </ul>
    </Container>
  );
};

export default Nav;
