import React from 'react';
import Layout from 'components/layout';
import ContactLogo from 'components/contactlogo';

const Contact = () => (
  <Layout>
    <ContactLogo></ContactLogo>
  </Layout>
);

export default Contact;
