import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import { Container, MobileContainer } from './header.css';
import Title from 'components/title';
import Nav from 'components/header/nav';
import { graphql } from 'gatsby';
import { BrowserView, MobileView } from 'react-device-detect';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Header = ({ title }) => {
  const [canRender, setCanRender] = useState(false);

  useEffect(() => setCanRender(true));

  const colors = ['#cc0300', '#00188f', '#009e71', '#ffdb0f', '#ffa8b2'];

  const changeBackground = () => {
    if (canRender) {
      const current = RGBToHex(
        document.body.style.backgroundColor.replace(/\s/g, '')
      );
      const newColor = colors[Math.floor(Math.random() * colors.length)];
      if (current !== newColor) {
        document.body.style.backgroundColor = newColor;
      } else {
        changeBackground();
      }
    }
  };

  function RGBToHex(rgb) {
    // Choose correct separator
    let sep = rgb.indexOf(',') > -1 ? ',' : ' ';
    // Turn "rgb(r,g,b)" into [r,g,b]
    rgb = rgb
      .substr(4)
      .split(')')[0]
      .split(sep);

    let r = (+rgb[0]).toString(16),
      g = (+rgb[1]).toString(16),
      b = (+rgb[2]).toString(16);

    if (r.length == 1) r = '0' + r;
    if (g.length == 1) g = '0' + g;
    if (b.length == 1) b = '0' + b;

    return '#' + r + g + b;
  }

  return (
    <AnimatedContainer>
      <BrowserView>
        <Container>
          <Link onClick={changeBackground()} to="/">
            <Title as="h1" size="large" image={null}>
              {title}
            </Title>
          </Link>

          <Nav />
        </Container>
      </BrowserView>
      <MobileView>
        <MobileContainer>
          {/* <Link onClick={changeBackground()} to="/">
          <Title as="h1" size="large" image={null}>
            {title}
          </Title>
        </Link> */}

          <Nav />
        </MobileContainer>
      </MobileView>
    </AnimatedContainer>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "/src/images/wm_icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
