import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from './title.css';
import logo from '../../images/wm_icon.png';

const Title = () => {
  return (
    // <Text as={as} size={size}>
    //   <span
    //     style={{
    //       color: `${children ? '#d01b22' : '#d01b22'}`,
    //       fontWeight: 'bold',
    //     }}
    //   >
    //     W
    //   </span>
    //   <span style={{ color: '#0a4b8d', fontWeight: 'bold' }}>O</span>
    //   <span style={{ color: '#fde223', fontWeight: 'bold' }}>L</span>
    //   <span style={{ color: '#23a183', fontWeight: 'bold' }}>F</span>
    //   <span style={{ color: '#d01b22', fontWeight: 'bold' }}>M</span>
    //   <span style={{ color: '#fddde3', fontWeight: 'bold' }}>I</span>
    //   <span style={{ color: '#0a4b8d', fontWeight: 'bold' }}>C</span>
    //   <span style={{ color: '#23a183', fontWeight: 'bold' }}>H</span>
    // </Text>
    <Logo src={logo} alt="Wolfmich" />
  );
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
  size: PropTypes.oneOf(['large']),
};

export default Title;
